import { useTheme } from '@emotion/react'

import { Link } from '@/lib/link'

import { LINKS } from './constants'

type Props = {
  id?: string
}

export function Footer({ id }: Props) {
  const theme = useTheme()

  return (
    <footer
      id={id}
      css={theme.mq({
        backgroundColor: 'white',
        boxShadow: '--shadows-reverse-md',
      })}
    >
      <div
        css={theme.mq({
          ...theme.layerStyles.landingSectionContainer,
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'white',
          fontWeight: '--fontWeights-normal',
          fontSize: {
            base: '--fontSizes-sm',
            md: '--fontSizes-md',
          },
          color: '--colors-gray-500',
          display: 'flex',
          flexFlow: { base: 'column-reverse nowrap', sm: 'row nowrap' },
          alignItems: {
            base: 'center',
            sm: 'space-between',
          },
          justifyContent: {
            base: 'center',
            sm: 'space-between',
          },
        })}
      >
        <p
          css={theme.mq({
            paddingTop: { base: 0, sm: '--space-4' },
            paddingBottom: '--space-4',
            marginRight: { base: 0, sm: '--space-6' },
          })}
        >
          &copy;{new Date().getFullYear()} nDataThoth Limited All Rights
          Reserved.
        </p>
        <section
          css={theme.mq({
            display: 'flex',
            paddingTop: '--space-4',
            paddingBottom: '--space-4',
            '& > a': {
              marginLeft: { base: '--space-5', md: '--space-16' },
            },
            '& > a:first-of-type': {
              marginLeft: 0,
            },
          })}
        >
          {LINKS.map(({ label, ...url }, index) => {
            return (
              <Link
                key={`${index}`}
                {...url}
                sx={{ ...theme.layerStyles.actionable }}
              >
                {label}
              </Link>
            )
          })}
        </section>
      </div>
    </footer>
  )
}
