import { useTheme } from '@emotion/react'

import { Link, URL } from '@/lib/link'

import { ResponsiveCSSObject } from '@/styles/types'

export type Props = {
  children: (props: any) => React.ReactNode
  sx?: ResponsiveCSSObject
  onClick?: () => void
} & URL

export function Button({ children, onClick, sx, ...url }: Props) {
  const theme = useTheme()

  return (
    <div
      css={theme.mq({
        '& > a': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '--sizes-full',
          height: '--sizes-full',
          padding: '--space-6',
        },
        transitionProperty: '--transition-property-common',
        transitionDuration: '--transition-duration-normal',
        transitionTimingFunction: '--transition-easing-ease-in-out',
        minWidth: { base: '--sizes-full', md: '--sizes-nav-md' },
        height: { base: '--sizes-nav-sm', md: '--sizes-nav-md' },
        backgroundColor: 'white',
        fontWeight: '--fontWeights-light',
        '&:hover': {
          backgroundColor: '--colors-primary-50',
          color: '--colors-primary-800',
          zIndex: 1,
        },
        '&:active': {
          boxShadow: `0px 0px 0px 2px currentColor`,
        },
        ...sx,
      })}
    >
      <Link onClick={onClick} {...url}>
        {children({ onClick })}
      </Link>
    </div>
  )
}
