import { useState } from 'react'
import { useTheme } from '@emotion/react'
import { animated, useSpring } from 'react-spring'

import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'
import { ResponsiveCSSObject } from '@/styles/types'

type Props = {
  onClick?: () => void
  isActive?: boolean
  sx?: ResponsiveCSSObject
}

export function Hamburger({ isActive = false, onClick, sx }: Props) {
  const theme = useTheme()

  const [isHover, setHover] = useState(false)

  const { topTransform, middleTransform, bottomTransform, opacity } = useSpring(
    {
      topTransform: isActive
        ? 'rotate(135deg) translate3d(0, 8px, 0) '
        : 'rotate(0deg) translate3d(0, 0px, 0)',
      middleTransform: isActive
        ? 'rotate(0deg) translate3d(-18px, 0px, 0)'
        : isHover
        ? 'rotate(0deg) translate3d(-8px, 0px, 0)'
        : 'rotate(0deg) translate3d(0px, 0px, 0)',
      bottomTransform: isActive
        ? 'rotate(-135deg) translate3d(0, -8px, 0) '
        : 'rotate(0deg) translate3d(0, 0px, 0)',
      opacity: isActive ? 0 : 1,
      config: DEFAULT_SPRING_CONFIG,
    },
  )

  return (
    <button
      aria-label="menu"
      css={theme.mq({
        width: '--sizes-nav-sm',
        height: '--sizes-nav-sm',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transitionProperty: 'color',
        transitionDuration: '--transition-duration-normal',
        transitionTimingFunction: '--transition-easing-ease-in-out',
        backgroundColor: 'white',
        border: 'none',
        '&:hover': {
          color: isActive ? '--colors-primary-600' : '--colors-primary-500',
        },
        ...sx,
      })}
      onClick={onClick}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <svg width="30" height="24" viewBox="-3 -3 30 24">
        <g fill="currentColor">
          <animated.rect
            style={{ transform: topTransform, transformOrigin: '12px 9px' }}
            width="24"
            height="2"
            x="0"
            y="0"
            rx="1"
          />
          <animated.rect
            style={{ transform: middleTransform, opacity }}
            width="16"
            height="2"
            x="8"
            y="8"
            rx="1"
          />
          <animated.rect
            style={{ transform: bottomTransform, transformOrigin: '12px 9px' }}
            width="24"
            height="2"
            y="16"
            rx="1"
          />
        </g>
      </svg>
    </button>
  )
}
