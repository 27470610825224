import { useState, useCallback } from 'react'

export type UseDisclosureReturn = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
}

export function useDisclosure(defaultIsOpen?: boolean): UseDisclosureReturn {
  const [isOpen, setOpen] = useState(Boolean(defaultIsOpen))

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onToggle = useCallback(() => {
    setOpen((isOpen) => !isOpen)
  }, [setOpen])

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}
