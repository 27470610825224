import { useTheme } from '@emotion/react'
import { animated, useSpring } from 'react-spring'

import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'
import { ResponsiveCSSObject } from '@/styles/types'

type Props = {
  isActive?: boolean
  onClick?: () => void
  sx?: ResponsiveCSSObject
}

export function MenuOverlay({ sx, onClick, isActive = false }: Props) {
  const theme = useTheme()

  const { opacity } = useSpring({
    opacity: isActive ? 1 : 0,
    config: DEFAULT_SPRING_CONFIG,
  })

  return (
    <animated.div
      style={{
        opacity,
        display: opacity.to((value) => (value <= 0.1 ? 'none' : 'block')),
      }}
      css={theme.mq({
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '--colors-blackAlpha-400',
        opacity: 0,
        ...sx,
      })}
      onClick={onClick}
    ></animated.div>
  )
}
