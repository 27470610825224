import { useState } from 'react'
import { useTheme } from '@emotion/react'
import Image from 'next/image'

import { useWindowResizeEffect } from '@/lib/hooks'
import { Link } from '@/lib/link'

import Logo from '@/images/logo.svg'

import { MenuMobile } from './MenuMobile'
import { MenuDesktop } from './MenuDesktop'
import { Hamburger } from './Hamburger'
import { MenuOverlay } from './MenuOverlay'

type Props = {}

export function Nav({}: Props) {
  const theme = useTheme()

  const [isManuMobileActive, setMenuMobileActive] = useState(false)

  useWindowResizeEffect(() => {
    if (!isManuMobileActive) return

    const width = document.body.getBoundingClientRect().width

    if (width > parseInt(theme.breakpoints.md as string)) {
      setMenuMobileActive(false)
    }
  })

  return (
    <nav
      css={theme.mq({
        width: '--sizes-full',
        backgroundColor: 'white',
        boxShadow: '--shadows-md',
        position: 'fixed',
        zIndex: '--zIndices-sticky',
        top: 0,
      })}
    >
      <MenuOverlay
        isActive={isManuMobileActive}
        onClick={() => setMenuMobileActive(false)}
      />
      <div
        css={theme.mq({
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          width: '--sizes-full',
          margin: 'auto',
          maxWidth: {
            ...theme.layerStyles.landingSectionContainer.maxWidth,
            base: '--sizes-full',
            sm: '--sizes-full',
          },
          minWidth: theme.layerStyles.landingSectionContainer.minWidth,
          paddingLeft: theme.layerStyles.landingSectionContainer.paddingLeft,
          position: 'relative',
        })}
      >
        <figure
          css={theme.mq({
            ...theme.layerStyles.actionable,
            position: 'relative',
            height: '--sizes-full',
            width: 'fit-content',
            alignSelf: 'center',
          })}
        >
          <Link to="LANDING" scrollOption={{ hashSpy: false }}>
            <Image src={Logo} alt="logo" width="164px" height="24px" priority />
          </Link>
        </figure>
        <MenuDesktop
          onClickButton={() => {
            setMenuMobileActive(false)
          }}
          sx={{
            display: { base: 'none', md: 'flex' },
          }}
        />
        <Hamburger
          isActive={isManuMobileActive}
          onClick={() => setMenuMobileActive((isActive) => !isActive)}
          sx={{
            display: {
              base: 'flex',
              md: 'none',
            },
          }}
        />
      </div>
      <MenuMobile
        isActive={isManuMobileActive}
        onClickButton={() => {
          setMenuMobileActive(false)
        }}
        sx={{
          display: { base: 'block', md: 'none' },
          position: 'relative',
        }}
      />
    </nav>
  )
}
