import { forwardRef } from 'react'
import { useTheme } from '@emotion/react'

import { ResponsiveCSSObject } from '@/styles/types'

type Props = {
  ratio: number
  children: React.ReactChild
  sx?: ResponsiveCSSObject
}

export const AspectRatio = forwardRef(function AspectRatio(
  { ratio, children, sx }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme()

  return (
    <div
      ref={ref}
      css={theme.mq({
        width: '--sizes-full',
        alignSelf: 'stretch',
        paddingTop: `${100 / ratio}%`,
        position: 'relative',
        height: 0,
        '& > *': {
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          height: '--sizes-full',
          maxHeight: '--sizes-full',
        },
        ...sx,
      })}
    >
      {children}
    </div>
  )
})
