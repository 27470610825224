import { useRef, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { useSpring, animated } from 'react-spring'

import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'
import { useWindowResizeEffect } from '@/lib/hooks'
import { ResponsiveCSSObject } from '@/styles/types'

import { LINKS } from './constants'
import { Button } from './Button'

type Props = {
  isActive?: boolean
  sx?: ResponsiveCSSObject
  onClickButton?: () => void
}

export function MenuMobile({ isActive = false, sx, onClickButton }: Props) {
  const theme = useTheme()
  const contentRef = useRef<HTMLParagraphElement>(null)

  const [{ opacity, maxHeight }, springApi] = useSpring(() => {
    return {
      to: {
        opacity: 0,
        maxHeight: 0,
      },
      config: DEFAULT_SPRING_CONFIG,
    }
  })

  useWindowResizeEffect(() => {
    if (!contentRef.current) return

    const { height } = contentRef.current.getBoundingClientRect()

    springApi.start({
      maxHeight: isActive ? height : 0,
      immediate: true,
    })
  })

  useEffect(() => {
    if (!contentRef.current) return

    const { height } = contentRef.current.getBoundingClientRect()

    springApi.start({
      maxHeight: isActive ? height : 0,
      opacity: isActive ? 1 : 0,
    })

    document.body.style.overflow = isActive ? 'hidden' : 'auto'
  }, [isActive])

  return (
    <animated.div
      style={{ opacity, maxHeight }}
      css={theme.mq({
        width: '--sizes-full',
        overflow: 'hidden',
        ...sx,
      })}
    >
      <div
        ref={contentRef}
        css={theme.mq({
          display: 'flex',
          flexFlow: 'column nowrap',
          maxHeight: '100vh',
          overflowY: 'auto',
        })}
      >
        {LINKS.map(({ children, onClick, ...rest }, index) => {
          return (
            <Button
              key={`${index}`}
              onClick={() => {
                if (onClick) onClick()
                if (onClickButton) onClickButton()
              }}
              {...rest}
            >
              {children}
            </Button>
          )
        })}
      </div>
    </animated.div>
  )
}
