import { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'

import { sortBps } from '@/lib/utils'
import { ResponsiveObject, Size } from '@/styles/types'
import { useWindowResizeEffect } from './useWindowResizeEffect'

export function useBreakpointValue<T = string | number>(
  valueObject: ResponsiveObject<T>,
): T | undefined {
  const theme = useTheme()

  const getBreakpointValue = (
    valueObject: ResponsiveObject<T>,
  ): T | undefined => {
    const breakpoints = sortBps(theme.breakpoints)

    const currentBp = (Object.entries(breakpoints) as [Size, string][])
      .reverse()
      .filter(([_key, value]) => {
        const _value = typeof value === 'string' ? parseInt(value) : value

        return _value < window?.innerWidth
      })

    const currentValue = currentBp.find(([bp]) => valueObject[bp])

    if (!currentValue) return valueObject['base']

    return valueObject[currentValue[0]] ?? valueObject['base']
  }

  const [value, setValue] = useState<T | undefined>(undefined)

  useEffect(() => setValue(getBreakpointValue(valueObject)), [valueObject])

  useWindowResizeEffect(() => setValue(getBreakpointValue(valueObject)))

  return value
}
