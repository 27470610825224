import { FaFacebookF, FaPhoneAlt } from 'react-icons/fa'
import { Props as ButtonProps } from './Button'

export const LINKS: ButtonProps[] = [
  {
    to: 'INFO',
    children: () => 'ทำงานอย่างไร',
  },
  {
    to: 'WHY_US',
    children: () => 'ประโยชน์',
  },
  {
    to: 'GET_STARTED',
    children: () => 'คู่มือการใช้งาน',
  },
  {
    to: 'APP_SIGN_IN',
    children: () => 'เข้าสู่ระบบ',
  },
  {
    to: 'PRICE',
    children: () => 'เปรียบเทียบราคาและแพ็กเกจ',
    sx: {
      backgroundColor: '--colors-primary-500',
      color: 'white',
      '&:hover': {
        backgroundColor: '--colors-primary-700',
        zIndex: 1,
      },
      '&:active': {},
    },
  },
  {
    to: 'FACEBOOK',
    children: () => <FaFacebookF size="24" />,
    sx: {
      backgroundColor: '--colors-facebook-500',
      color: 'white',
      width: {
        base: '--sizes-full',
        md: '--sizes-nav-md',
      },
      a: {
        padding: 0,
      },
      '&:hover': {
        backgroundColor: '--colors-facebook-700',
        zIndex: 1,
      },
      '&:active': {},
    },
  },
  {
    to: 'TEL',
    children: (props) => (
      <a {...props}>
        <FaPhoneAlt size="24" />
      </a>
    ),
    sx: {
      width: { base: '--sizes-full', md: '--sizes-nav-md' },
      a: {
        padding: 0,
      },
    },
  },
]
