import { URL } from '@/lib/link'

type Link = {
  label: React.ReactNode
} & URL

export const LINKS: Link[] = [
  {
    to: 'HELP',
    label: 'Help',
  },
  {
    to: 'TERMS_OF_USE',
    label: 'Terms of Use',
  },
  {
    to: 'PRIVACY_POLICY',
    label: 'Privacy Policy',
  },
]
