import { Nav } from '@/components/Nav'
import { Footer } from '@/components/Footer'

type Props = {
  children: React.ReactNode
}

export function MainLayout({ children }: Props) {
  return (
    <>
      <Nav />
      <main>{children}</main>
      <Footer />
    </>
  )
}
