import { useTheme } from '@emotion/react'

import { ResponsiveCSSObject } from '@/styles/types'

import { LINKS } from './constants'
import { Button } from './Button'

type Props = {
  sx?: ResponsiveCSSObject
  onClickButton?: () => void
}

export function MenuDesktop({ sx, onClickButton }: Props) {
  const theme = useTheme()

  return (
    <div
      css={theme.mq({
        flexFlow: 'row nowrap',
        ...sx,
      })}
    >
      {LINKS.map(({ children, onClick, ...rest }, index) => {
        return (
          <Button
            key={`${index}`}
            onClick={() => {
              if (onClick) onClick()
              if (onClickButton) onClickButton()
            }}
            {...rest}
          >
            {children}
          </Button>
        )
      })}
    </div>
  )
}
